import {
  useState,
  useEffect
} from 'react'
import { 
  Col,
  ModalBody, ModalFooter, Row 
} from 'react-bootstrap'
import {
  Formik
} from 'formik'
import * as Yup from 'yup'
import Axios from 'axios'
import { 
  ActionButton, 
  DataStatus, 
  Table, 
  Th,
  Td,
  ThFixed,
  DeleteButton,
  Modal,
  SelectSearch,
  RadioButtonWrapper,
  RadioButton,
  Input,
  PopUpAlert,
  DeleteModal
} from 'components'
import { 
  RupiahConvert 
} from 'utilities'
import {
  SubKegiatanApi
} from 'api'

const TableSection = ({dataTable}) => {
  let total = 0;
  // Mendapatkan sub total
  const getSubTotal = (qty, harga) => {
    const getQty = qty ? parseFloat(qty) : 0
    const getHarga = harga ? parseFloat(harga) : 0

    return getQty * getHarga
  }

  return (
    <>
      <div className="mt-4" style={{marginBottom: 10}}>
        <b>List Sumber Daya</b>
      </div>
      <Table responsive>
        <thead className="bg-light">
          <tr>
            <ThFixed>No</ThFixed>
            <Th textRight width={140}>Kategori Anggaran</Th>
            <Th style={{minWidth: 200}}>Item Sumber Daya</Th>
            <Th textRight width={150}>Sumber Pengadaan</Th>
            <Th textRight width={130}>Qty. Sumber Daya</Th>
            <Th textRight width={130}>Harga Satuan</Th>
            <Th textRight width={130}>Sub Total</Th>
          </tr>
        </thead>
        <tbody>
          {dataTable && dataTable.length > 0
            ? dataTable.map((val, index) => {
              total = total + getSubTotal(val.qty_sumber_daya, val.harga_satuan)

              return (
                <tr key={index}>
                  <Td textCenter>{index + 1}</Td>
                  <Td>{val.nama_kategori_sumber_daya}</Td>
                  <Td>{val.item_sumber_daya}</Td>
                  <Td>{val.kode_sumber_pengadaan ?? '-'}</Td>
                  <Td>{val.qty_item} {val.nama_satuan}</Td>
                  <Td textRight>{RupiahConvert(String(parseInt(val.harga_satuan ?? "0"))).detail}</Td>
                  <Td textRight>{RupiahConvert(getSubTotal(val.qty_sumber_daya, val.harga_satuan).toString()).detail}</Td>
                </tr>
              )
            })
            : <tr>
                <td colSpan={8}>
                  <DataStatus text="Tidak ada data" />
                </td>
              </tr>
          }

          {dataTable && dataTable.length > 0 && 
            <tr>
              <Td colSpan="6" textRight className="font-weight-bold">Total</Td>
              <Td className="text-nowrap" textRight>{RupiahConvert(total.toString()).detail}</Td>
            </tr>
          }
        </tbody>
      </Table>
    </>
  )
}

export default TableSection
