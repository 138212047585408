import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Th,
  Tr,
  Td,
  TBody,
  CRUDLayout,
  TdFixed,
  ThFixed,
  THead,
} from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { PPAApi } from "../../../api";

const DetailPPA = ({ setNavbarTitle }) => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams();
  const { no_ppa, tab } = location.state;
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [dataPPA, setDataPPA] = useState({});
  const [approveStatus, setApproveStatus] = useState("V");
  const [dataApproval, setDataApproval] = useState([]);

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  const tableStyling = {
    padding: "0.5px",
    fontSize: "14px",
    verticalAlign: "middle",
  };

  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });

  const getInitialData = () => {
    setIsPageLoading(true);

    // GET SINGLE DETAIL
    Axios.all([PPAApi.getSingle({ no_ppa: no_ppa, id_ppa: id })])
      .then(
        Axios.spread((data) => {
          const { approval, detail } = data.data.data;
          const behavior = data.data.data.behavior ? data.data.data.behavior : "V";
          setDataPPA(detail);
          setDataApproval(approval ?? []);
          setApproveStatus(behavior.toUpperCase());
        })
      )
      .catch(() => {
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                  ? "Reject"
                  : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    console.log(approveStatus);

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval PPA</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {dataApproval.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))}
          </div>
          <hr />
          {/* HIDE INPUT TEXT AREA KETIKA TAB HISTORY */}
          {tab !== 'history' && <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject PPA</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve PPA</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REJ",
                          title: <span className="text-danger">Reject PPA</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "REV",
                          title: <span className="text-warning">Revise PPA</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "APP",
                          title: <span className="text-success">Approve PPA</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>}
        </Card.Body>
      </Card>
    );
  };

  const DetailData = () => {
    const InfoItem = ({ title, text }) => (
      <tr>
        <td className="align-top" style={{fontSize: 14}}>{title}</td>
        <td className="pl-4 pr-2 align-top" style={{fontSize: 14}}>:</td>
        <td className="align-top" style={{fontSize: 14}}>{text}</td>
      </tr>
    );

    const ShowData = ({ title, text }) => (
      <div className="mb-2">
        <div className="text-secondary text-capitalize">
          <small>{title}</small>
        </div>
        <div className="text-dark">{text ? text : "-"}</div>
      </div>
    );

    return (
      <Row>
        <Col>
          <table>
            <InfoItem title="Program" text={dataPPA?.nama_program ?? "-"} />
            <InfoItem title="Kegiatan" text={dataPPA?.nama_kegiatan ?? "-"} />
            <InfoItem title="Sub Kegiatan" text={dataPPA?.nama_sub_kegiatan ?? "-"} />
            <InfoItem title="Periode Sub Kegiatan" text={`${dataPPA?.tgl_mulai_sub_kegiatan ? DateConvert(new Date(dataPPA.tgl_mulai_sub_kegiatan)).defaultDMY : "-"} s.d. ${dataPPA?.tgl_selesai_sub_kegiatan ? DateConvert(new Date(dataPPA.tgl_selesai_sub_kegiatan)).defaultDMY : "-"}`} />
          </table>
        </Col>

        <Col>
          <table>
            <InfoItem title="Tgl. PPA" text={dataPPA?.tgl_ppa ? DateConvert(new Date(dataPPA.tgl_ppa)).defaultDMY : "-"} />
            <InfoItem title="No. PPA" text={dataPPA?.no_ppa ?? "-"} />
            <InfoItem title="Sumber Daya" text={dataPPA?.item_sumber_daya_tampil ?? "-"} />
            <InfoItem title="Sumber Pengadaan" text={dataPPA?.kode_sumber_pengadaan ? dataPPA.kode_sumber_pengadaan === 'procurement' ? 'Procurement' : 'Petty Cash' : "-"} />
            <InfoItem title="Tipe Anggaran" text={`${dataPPA?.nomor_akun ? dataPPA.nomor_akun +" - " : ""}${dataPPA?.nama_akun ?? ""}`} />
            <InfoItem title="Qty. Sumber Daya" text={`${dataPPA?.qty_sumber_daya ? parseInt(dataPPA.qty_sumber_daya) : 0} Orang`} />
            <InfoItem title="Harga Satuan" text={dataPPA?.harga_satuan_ppa ? RupiahConvert(String(parseInt(dataPPA.harga_satuan_ppa))).detail : RupiahConvert("0").detail} />
            <InfoItem title="Harga Total" text={dataPPA?.harga_satuan_ppa && dataPPA?.qty_sumber_daya ? RupiahConvert(String(parseInt(dataPPA.harga_satuan_ppa ?? 0) * parseInt(dataPPA.qty_sumber_daya ?? 0))).detail : RupiahConvert("0").detail} />
          </table>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    setNavbarTitle("Approval PPA");
    getInitialData();
    checkAlert();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data PPA</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : (
            <>
              <DetailData />
            </>
          )}
        </Card.Body>
      </Card>

      <Formik
        initialValues={{ catatan: "" }}
        validationSchema={Yup.object().shape({
          catatan: Yup.string().required("Masukan Catatan"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const finalValues = {
            ...values,
            no_transaksi: dataPPA.no_transaksi,
            status_approval: modalConfig.type,
            tgl_approval: DateConvert(new Date()).default,
            approval_baseline: dataPPA.baseline,
          };

          PPAApi.approve(finalValues)
            .then((res) => {
              history.push("/human-resource/approval/ppa", {
                alert: {
                  show: true,
                  variant: "primary",
                  text: `${modalConfig.type == "APP"
                    ? "Approve"
                    : modalConfig.type == "REV"
                      ? "Revise"
                      : "Reject"
                    } data berhasil!`,
                },
              });
            })
            .catch((err) => {
              setAlertConfig({
                show: true,
                variant: "danger",
                text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
              });
              setSubmitting(false);
            })
            .finally(() => setModalConfig({ show: false }));
        }}
      >
        {({
          values,
          isSubmitting,
          handleChange,
          handleSubmit,
          errors,
          touched,
          validateForm,
          dirty,
        }) => (
          <>
            <FormCard
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
              validateForm={validateForm}
              dirty={dirty}
            />
            <ModalSection
              values={values}
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
          </>
        )}
      </Formik>
    </>
  );
};

export default DetailPPA;
