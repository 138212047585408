import Services from "../../services";

class PPAApi {
  // API SERVER
  get(params) {
    return Services.get("approval_ppa/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_ppa/history/list", { params });
  }

  getSingle(params) {
    return Services.get("approval_ppa/detail", { params });
  }

  approve(data) {
    return Services.post("approval_ppa/approve", data);
  }
}

export default new PPAApi();
