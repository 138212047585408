import Services from "../../services";

class RABApi {
  get(params) {
    return Services.get("approval_rab/list", { params });
  }

  getHistory(params) {
    return Services.get("approval_rab/history/list", { params });
  }

  getSingle(params) {
    return Services.get("approval_rab/detail", { params });
  }

  getAnalisa(params) {
    return Services.get("approval_rab/analisa_barang_jadi_single", { params });
  }

  approve(data) {
    return Services.post("approval_rab/approve", data);
  }
}

export default new RABApi();
