import { DetailInvoice, MainApprovalInvoice } from "pages/Approval/ApprovalInvoice";
import {
  PurchaseRequest,
  MainApprovalProgram,
  DetailProgram,
  MainApprovalRAP,
  DetailRAP,
  MainApprovalSPK,
  DetailApprovalSPK,
  MainApprovalDeliveryOrder,
  DetailApprovalDeliveryOrder,
  MainApprovalFakturPenjualan,
  DetailFakturPenjualan,
  MainApprovalKegiatan,
  DetailKegiatan,
  MainApprovalMutasiAntarGudang,
  DetailMutasiAntarGudang,
  MainApprovalPenerimaanBarang,
  DetailPenerimaanBarang,
  MainApprovalPenerimaanFaktur,
  DetailApprovalPenerimaanFaktur,
  MainApprovalPPA,
  DetailPPA,
  MainApprovalPurchaseOrder,
  DetailApprovalPurchaseOrder,
  MainApprovalPurchaseRequest,
  DetailPurchaseRequest,
  MainApprovalSalesOrder,
  DetailSalesOrder,
  MainApprovalSeleksiVendor,
  DetailApprovalSeleksiVendor,
  MainApprovalSuratJalan,
  DetailSuratJalan,
  MainApprovalPenawaran,
  DetailPenawaran,
  MainApprovalRAB,
  DetailRAB,
  MainApprovalRAE,
  DetailRAE,
  MainApprovalSubKegiatan,
  DetailSubKegiatan,
  MainApprovalRKA,
  DetailRKA,
  // SIA
  MainApprovalPengakuanHutang,
  DetailPengakuanHutang,
  MainApprovalJurnalUmum,
  DetailJurnalUmum,
  MainApprovalVoucherPembayaran,
  DetailVoucherPembayaran,
  MainApprovalPengakuanPiutang,
  DetailPengakuanPiutang,
  MainApprovalPenyesuaianHutang,
  DetailPenyesuaianHutang,
  MainApprovalPenyesuaianPiutang,
  DetailPenyesuaianPiutang,
  MainApprovalBayarDPVendor,
  DetailBayarDPVendor,
  MainApprovalPenerimaanDPCustomer,
  DetailPenerimaanDPCustomer,
  MainApprovalVoucherGC,
  DetailVoucherGC,
  MainApprovalInvoiceGC,
  DetailInvoiceGC,
  MainApprovalNonVoucherInvoice,
  DetailNonVoucherInvoice,
  MainApprovalMutasi,
  DetailMutasi,
} from "../pages/Approval";

/* 
  MENU REQUIREMENTS
    > EXACT = OPTIONAL (TRUE/FALSE)
    > PATH  = REQUIRED
    > PAGE  = REQUIRED
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
*/

export default [
  {
    // exact: true,
    // route: "/profil",
    // hak: [
    //   "HRDU",
    //   "ASM",
    //   "MKT",
    //   "CRM",
    //   "INV",
    //   "ANG",
    //   "HRDA",
    //   "HRDU_APR_REGKEG",
    //   "HRDU_APR_PR",
    //   "HRDU_APR_REGRAB",
    //   "HRDU_APR_REGPNW",
    //   "HRDU_APR_PVSPR",
    //   "HRDU_APR_REGPRO",
    //   "HRDU_APR_PENPO",
    //   "HRDU_APR_REGFPNJ",
    //   "HRDU_APR_PENFPNJ",
    //   "HRDU_APR_REGDO",
    //   "HRDU_APR_REGPO",
    //   "HRDU_APR_REGRAE",
    //   "HRDU_APR_REGRAP",
    //   "HRDU_APR_REGSJ",
    //   "HRDU_APR_REGSO",
    //   "HRDU_APR_REGSPK",
    //   "HRDU_APR_RK",
    //   "HRDU_APR_REGPPA",
    //   "HRDU_APR_MAG"
    // ],
    // page: Profil,
  },
  // Approval Program
  {
    exact: true,
    route: "/human-resource/approval/program",
    hak: ["HRDU", "HRDU_APR_REGPRG"],
    page: MainApprovalProgram,
  },
  {
    exact: true,
    route: "/human-resource/approval/program/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGPRG"],
    page: DetailProgram,
  },
  // Approval Kegiatan
  {
    exact: true,
    route: "/human-resource/approval/kegiatan",
    hak: ["HRDU", "HRDU_APR_REGKEG", "HRDU_APR_RK"],
    page: MainApprovalKegiatan,
  },
  {
    exact: true,
    route: "/human-resource/approval/kegiatan/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGKEG", "HRDU_APR_RK"],
    page: DetailKegiatan,
  },

  // Approval Sub Kegiatan
  {
    exact: true,
    route: "/human-resource/approval/sub-kegiatan",
    hak: ["HRDU", "HRDU_APR_REGKEG", "HRDU_APR_RK"],
    page: MainApprovalSubKegiatan,
  },
  {
    exact: true,
    route: "/human-resource/approval/sub-kegiatan/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGKEG", "HRDU_APR_RK"],
    page: DetailSubKegiatan,
  },

  // Approval RKA
  {
    exact: true,
    route: "/human-resource/approval/rka",
    hak: ["HRDU", "HRDU_APR_REGRKA", "HRDU_APR_REGRKA"],
    page: MainApprovalRKA,
  },
  {
    exact: true,
    route: "/human-resource/approval/rka/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGRKA", "HRDU_APR_REGRKA"],
    page: DetailRKA,
  },

  // Approval PPA
  {
    exact: true,
    route: "/human-resource/approval/ppa",
    hak: ["HRDU", "HRDU_APR_REGPPA"],
    page: MainApprovalPPA,
  },
  {
    exact: true,
    route: "/human-resource/approval/ppa/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGPPA"],
    page: DetailPPA,
  },
  // Approval Pengakuan Hutang
  {
    exact: true,
    route: "/human-resource/approval/pengakuan-hutang",
    hak: ["HRDU", "HRDU_APR_REGAPPH"],
    page: MainApprovalPengakuanHutang,
  },
  {
    exact: true,
    route: "/human-resource/approval/pengakuan-hutang/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGAPPH"],
    page: DetailPengakuanHutang,
  },

  // Approval Jurnal Umum
  {
    exact: true,
    route: "/human-resource/approval/jurnal-umum",
    hak: ["HRDU", "HRDU_APR_REGGL"],
    page: MainApprovalJurnalUmum,
  },
  {
    exact: true,
    route: "/human-resource/approval/jurnal-umum/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGL"],
    page: DetailJurnalUmum,
  },

  // Approval Voucher Pembayaran
  {
    exact: true,
    route: "/human-resource/approval/voucher",
    hak: ["HRDU", "HRDU_APR_REGAPPVC"],
    page: MainApprovalVoucherPembayaran,
  },
  {
    exact: true,
    route: "/human-resource/approval/voucher/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGAPPVC"],
    page: DetailVoucherPembayaran,
  },

  // Approval Penyesuaian Hutang
  {
    exact: true,
    route: "/human-resource/approval/penyesuaian-hutang",
    hak: ["HRDU", "HRDU_APR_REGAPPSH"],
    page: MainApprovalPenyesuaianHutang,
  },
  {
    exact: true,
    route: "/human-resource/approval/penyesuaian-hutang/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGAPPSH"],
    page: DetailPenyesuaianHutang,
  },

  // Approval Pengakuan Piutang
  {
    exact: true,
    route: "/human-resource/approval/pengakuan-piutang",
    hak: ["HRDU", "HRDU_APR_REGARPP"],
    page: MainApprovalPengakuanPiutang,
  },
  {
    exact: true,
    route: "/human-resource/approval/pengakuan-piutang/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGARPP"],
    page: DetailPengakuanPiutang,
  },

  // Approval Invoice
  {
    exact: true,
    route: "/human-resource/approval/invoice",
    hak: ["HRDU", "HRDU_APR_REGARPIV"],
    page: MainApprovalInvoice,
  },
  {
    exact: true,
    route: "/human-resource/approval/invoice/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGARPIV"],
    page: DetailInvoice,
  },

  // Approval Penyesuaian Piutang
  {
    exact: true,
    route: "/human-resource/approval/penyesuaian-piutang",
    hak: ["HRDU", "HRDU_APR_REGARPNP"],
    page: MainApprovalPenyesuaianPiutang,
  },
  {
    exact: true,
    route: "/human-resource/approval/penyesuaian-piutang/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGARPNP"],
    page: DetailPenyesuaianPiutang,
  },

  // Approval Bayar DP Vendor
  {
    exact: true,
    route: "/human-resource/approval/bayar-dp-vendor",
    hak: ["HRDU", "HRDU_APR_REGGCBDPV"],
    page: MainApprovalBayarDPVendor,
  },
  {
    exact: true,
    route: "/human-resource/approval/bayar-dp-vendor/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGCBDPV"],
    page: DetailBayarDPVendor,
  },

  // Approval Penerimaan DP Customer
  {
    exact: true,
    route: "/human-resource/approval/penerimaan-dp-customer",
    hak: ["HRDU", "HRDU_APR_REGGCPDPK"],
    page: MainApprovalPenerimaanDPCustomer,
  },
  {
    exact: true,
    route: "/human-resource/approval/penerimaan-dp-customer/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGCPDPK"],
    page: DetailPenerimaanDPCustomer,
  },

  // Approval Pembayaran Voucher
  {
    exact: true,
    route: "/human-resource/approval/pembayaran-voucher",
    hak: ["HRDU", "HRDU_APR_REGGCPLKB"],
    page: MainApprovalVoucherGC,
  },
  {
    exact: true,
    route: "/human-resource/approval/pembayaran-voucher/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGCPLKB"],
    page: DetailVoucherGC,
  },

  // Approval Pembayaran Invoice
  {
    exact: true,
    route: "/human-resource/approval/pembayaran-invoice",
    hak: ["HRDU", "HRDU_APR_REGGCPNKB"],
    page: MainApprovalInvoiceGC,
  },
  {
    exact: true,
    route: "/human-resource/approval/pembayaran-invoice/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGCPNKB"],
    page: DetailInvoiceGC,
  },

  // Approval Non Voucher Invoice
  {
    exact: true,
    route: "/human-resource/approval/non-voucher-invoice",
    hak: ["HRDU", "HRDU_APR_REGGCNON"],
    page: MainApprovalNonVoucherInvoice,
  },
  {
    exact: true,
    route: "/human-resource/approval/non-voucher-invoice/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGCNON"],
    page: DetailNonVoucherInvoice,
  },

  // Approval Mutasi
  {
    exact: true,
    route: "/human-resource/approval/mutasi",
    hak: ["HRDU", "HRDU_APR_REGGCMTKB"],
    page: MainApprovalMutasi,
  },
  {
    exact: true,
    route: "/human-resource/approval/mutasi/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGGCMTKB"],
    page: DetailMutasi,
  },

  // Approval RAE
  {
    exact: true,
    route: "/human-resource/approval/rae",
    hak: ["HRDU", "HRDU_APR_REGRAE"],
    page: MainApprovalRAE,
  },
  {
    exact: true,
    route: "/human-resource/approval/rae/detail",
    hak: ["HRDU", "HRDU_APR_REGRAE"],
    page: DetailRAE,
  },

  // Approval RAB
  {
    exact: true,
    route: "/human-resource/approval/rab",
    hak: ["HRDU", "HRDU_APR_REGRAB"],
    page: MainApprovalRAB,
  },
  {
    exact: true,
    route: "/human-resource/approval/rab/detail/:id_rab",
    hak: ["HRDU", "HRDU_APR_REGRAB"],
    page: DetailRAB,
  },

  // Approval RAP
  {
    exact: true,
    route: "/human-resource/approval/rap",
    hak: ["HRDU", "HRDU_APR_REGRAP"],
    page: MainApprovalRAP,
  },
  {
    exact: true,
    route: "/human-resource/approval/rap/detail",
    hak: ["HRDU", "HRDU_APR_REGRAP"],
    page: DetailRAP,
  },

  // Approval Penawaran
  {
    exact: true,
    route: "/human-resource/approval/penawaran",
    hak: ["HRDU", "HRDU_APR_REGPNW"],
    page: MainApprovalPenawaran,
  },
  {
    exact: true,
    route: "/human-resource/approval/penawaran/detail/:id_penawaran",
    hak: ["HRDU", "HRDU_APR_REGPNW"],
    page: DetailPenawaran,
  },

  // Approval Purchase Request
  {
    exact: true,
    route: "/human-resource/approval/purchase-request",
    hak: ["HRDU", "HRDU_APR_PR"],
    page: MainApprovalPurchaseRequest,
  },
  {
    exact: true,
    route: "/human-resource/approval/purchase-request/detail/:id",
    hak: ["HRDU", "HRDU_APR_PR"],
    page: DetailPurchaseRequest,
  },

  // Approval Penerimaan Barang
  {
    exact: true,
    route: "/human-resource/approval/penerimaan-barang",
    hak: ["HRDU", "HRDU_APR_PENPO"],
    page: MainApprovalPenerimaanBarang,
  },
  {
    exact: true,
    route: "/human-resource/approval/penerimaan-barang/detail/:id",
    hak: ["HRDU", "HRDU_APR_PENPO"],
    page: DetailPenerimaanBarang,
  },

  // Approval Seleksi Vendor
  {
    exact: true,
    route: "/human-resource/approval/seleksi-vendor",
    hak: ["HRDU", "HRDU_APR_PVSPR"],
    page: MainApprovalSeleksiVendor,
  },
  {
    exact: true,
    route: "/human-resource/approval/seleksi-vendor/detail",
    hak: ["HRDU", "HRDU_APR_PVSPR"],
    page: DetailApprovalSeleksiVendor,
  },

  // Approval Purchase Order
  {
    exact: true,
    route: "/human-resource/approval/purchase-order",
    hak: ["HRDU", "HRDU_APR_REGPO"],
    page: MainApprovalPurchaseOrder,
  },
  {
    exact: true,
    route: "/human-resource/approval/purchase-order/detail",
    hak: ["HRDU", "HRDU_APR_REGPO"],
    page: DetailApprovalPurchaseOrder,
  },

  // Approval Sales Order
  {
    exact: true,
    route: "/human-resource/approval/sales-order",
    hak: ["HRDU", "HRDU_APR_REGSO"],
    page: MainApprovalSalesOrder,
  },
  {
    exact: true,
    route: "/human-resource/approval/sales-order/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGSO"],
    page: DetailSalesOrder,
  },

  // Approval SPK
  {
    exact: true,
    route: "/human-resource/approval/surat-perjanjian-kerja",
    hak: ["HRDU", "HRDU_APR_REGSPK"],
    page: MainApprovalSPK,
  },
  {
    exact: true,
    route: "/human-resource/approval/surat-perjanjian-kerja/detail",
    hak: ["HRDU", "HRDU_APR_REGSPK"],
    page: DetailApprovalSPK,
  },

  // Approval Faktur Penjualan
  {
    exact: true,
    route: "/human-resource/approval/faktur-penjualan",
    hak: ["HRDU", "HRDU_APR_REGFPNJ"],
    page: MainApprovalFakturPenjualan,
  },
  {
    exact: true,
    route: "/human-resource/approval/faktur-penjualan/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGFPNJ"],
    page: DetailFakturPenjualan,
  },

  // Approval Delivery Order
  {
    exact: true,
    route: "/human-resource/approval/delivery-order",
    hak: ["HRDU", "HRDU_APR_REGDO"],
    page: MainApprovalDeliveryOrder,
  },
  {
    exact: true,
    route: "/human-resource/approval/delivery-order/detail",
    hak: ["HRDU", "HRDU_APR_REGDO"],
    page: DetailApprovalDeliveryOrder,
  },

  // Approval Surat Jalan
  {
    exact: true,
    route: "/human-resource/approval/surat-jalan",
    hak: ["HRDU", "HRDU_APR_REGSJ"],
    page: MainApprovalSuratJalan,
  },
  {
    exact: true,
    route: "/human-resource/approval/surat-jalan/detail/:id",
    hak: ["HRDU", "HRDU_APR_REGSJ"],
    page: DetailSuratJalan,
  },
  // {
  //   exact: true,
  //   route: "/human-resource/approval/penerimaan-faktur",
  //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
  //   page: MainApprovalPenerimaanFaktur,
  // },
  // {
  //   exact: true,
  //   route: "/human-resource/approval/penerimaan-faktur/detail",
  //   hak: ["HRDU", "HRDU_APR_PENFPNJ"],
  //   page: DetailApprovalPenerimaanFaktur,
  // },

  // Approval Mutasi Antar Gudang
  {
    exact: true,
    route: "/human-resource/approval/mutasi-antar-gudang",
    hak: ["HRDU", "HRDU_APR_MAG"],
    page: MainApprovalMutasiAntarGudang,
  },
  {
    exact: true,
    route: "/human-resource/approval/mutasi-antar-gudang/detail/:id",
    hak: ["HRDU", "HRDU_APR_MAG"],
    page: DetailMutasiAntarGudang,
  },
];
