import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Axios from "axios";
import {
  DatePicker,
  InfoItemVertical,
  Input,
  RadioButton,
  SelectSearch,
  TextArea,
} from "components";
import { DateConvert } from "utilities";
import { SubKegiatanApi } from "api";

const FormSection = ({ formik, dataInfo }) => {
  const today = DateConvert(new Date()).default;
  const { values, errors, touched, handleChange, setFieldValue, setValues } = formik;
  const [dataPenanggungJawab, setDataPenanggungJawab] = useState([]);
  const [loading, setLoading] = useState({
    nomor: false,
    penanggungJawab: false,
  });

  console.log("test", dataInfo);

  // Mapping data
  const mappingDataPJ = (data) =>
    data.map((val) => {
      return {
        value: val.id_karyawan,
        label: val.nama_karyawan,
      };
    });

  // Fetch saat pertama kali page dibuka
  const getInitialData = () => {
    setLoading({
      nomor: true,
      penanggungJawab: true,
    });

    Axios.all([SubKegiatanApi.getSingle()])
      .then(
        Axios.spread((pj) => {
          const rawDataPJ = pj.data.data.detail;
          const mapDataPJ = mappingDataPJ(rawDataPJ ?? []);

          setDataPenanggungJawab(mapDataPJ);
        })
      )
      .catch(() => {
        // window.alert("Gagal memuat data penanggung jawab!");
      })
      .finally(() => {
        setLoading({
          nomor: false,
          penanggungJawab: false,
        });
      });
  };

  console.log("data", dataPenanggungJawab);
  // Fetch nomor
  const getNomorHandler = (date) => {
    setLoading({
      ...loading,
      nomor: true,
    });

    SubKegiatanApi.getNomor({ tanggal: date })
      .then((res) => {
        const nomor = res.data.data;

        setValues({
          ...values,
          tgl_sub_kegiatan: date,
          no_sub_kegiatan: nomor,
        });
      })
      .catch(() => {
        setValues({
          ...values,
          tgl_sub_kegiatan: "",
          no_sub_kegiatan: "",
        });
      })
      .finally(() => {
        setLoading({
          ...loading,
          nomor: false,
        });
      });
  };

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <>
      <Row>
        <Col md>
          <Row>
            {/* Tanggal Sub Kegiatan */}
            <Col md>
              <InfoItemVertical label="Tgl. Sub Kegiatan" text={values.tgl_sub_kegiatan} />

              {/* No Sub Kegiatan */}
              <InfoItemVertical label="No. Sub Kegiatan" text={values.no_sub_kegiatan} />
            </Col>
          </Row>
          <Row>
            {/* Tanggal Mulai */}
            <Col md>
              <InfoItemVertical label="Tgl. Mulai" text={values.tgl_mulai} />
              {/* Tanggal Selesai */}
              <InfoItemVertical label="Tgl. Selesai" text={values.tgl_selesai} />
            </Col>
          </Row>
        </Col>
        <Col md>
          {/* Nama Sub Kegiatan */}
          <InfoItemVertical label="Nama Sub Kegiatan" text={values.nama_sub_kegiatan} />
          {/* Penanggung Jawab */}
          <InfoItemVertical label="Penanggung Jawab" text={values.penanggung_jawab} />

          {/* Non Aset */}
          <RadioButton
            label="Non Aset"
            name="type"
            id="non-aset"
            checked={!values.is_aset}
            onChange={() =>
              setValues({
                ...values,
                is_aset: false,
                is_wip: false,
              })
            }
            disabled
          />
          {/* Aset */}
          <RadioButton
            label="Aset"
            name="type"
            id="aset"
            checked={values.is_aset}
            onChange={() =>
              setValues({
                ...values,
                is_aset: true,
                is_wip: true,
              })
            }
            disabled
          />
          <div className="d-flex flex-column ml-4">
            <RadioButton
              label="WIP"
              name="aset"
              value="wip"
              id="wip"
              checked={values.is_wip && values.is_aset}
              onChange={() => setFieldValue("is_wip", true)}
              disabled
            />
            <RadioButton
              label="Non WIP"
              name="aset"
              value="non wip"
              id="non-wip"
              checked={!values.is_wip && values.is_aset}
              onChange={() => setFieldValue("is_wip", false)}
              disabled
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md>
          <InfoItemVertical label="Keterangan" text={values.keterangan} />
        </Col>
        <Col md className="d-flex flex-column pt-md-3"></Col>
      </Row>
    </>
  );
};

export default FormSection;
