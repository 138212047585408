import React from "react";
import { Table, THead, TBody, Tr, ThFixed, TdFixed, Th, Td } from "components";
import { RupiahConvert } from "utilities";

const TableSection = ({ data }) => {
  let dataTable = data ?? [];
  const totalDebit = () => {
    const total = dataTable.reduce((prev, { debet }) => {
      const sum = parseFloat(prev) + parseFloat(debet);
      return sum;
    }, 0);
    return total;
  };
  const totalKredit = () => {
    const total = dataTable.reduce((prev, { kredit }) => {
      const sum = parseFloat(prev) + parseFloat(kredit);
      return sum;
    }, 0);
    return total;
  };
  const DataTable = () => (
    <Table>
      <THead>
        <Tr>
          <ThFixed>No</ThFixed>
          <ThFixed>No. Akun</ThFixed>
          <Th>Nama Akun</Th>
          <Th>Keterangan</Th>
          <Th>Debet</Th>
          <Th>Kredit</Th>
        </Tr>
      </THead>
      <TBody>
        {dataTable.map((val, index) => (
          <Tr>
            <TdFixed>{index + 1}</TdFixed>
            <TdFixed>{val.nomor_akun ?? "-"}</TdFixed>
            <Td>{val.nama_akun ?? "-"}</Td>
            <Td>{val.keterangan ?? "-"}</Td>
            <Td textRight>
              <div className="text-nowrap">
                {RupiahConvert(parseInt(val.debet).toString()).detail ?? "-"}
              </div>
            </Td>

            <Td textRight>
              <div className="text-nowrap">
                {RupiahConvert(parseInt(val.kredit).toString()).detail ?? "-"}
              </div>
            </Td>
          </Tr>
        ))}
        <Tr>
          <Td colSpan={4} textRight>
            <div style={{ fontWeight: "bold", fontSize: 14 }} className="text-nowrap">
              Total
            </div>
          </Td>
          <Td textRight>
            <div style={{ fontWeight: "bold", fontSize: 14 }} className="text-nowrap">
              {RupiahConvert(parseInt(totalDebit()).toString()).detail}
            </div>
          </Td>
          <Td textRight>
            <div style={{ fontWeight: "bold", fontSize: 14 }} className="text-nowrap">
              {RupiahConvert(parseInt(totalKredit()).toString()).detail}
            </div>
          </Td>
        </Tr>
      </TBody>
    </Table>
  );
  return (
    <>
      <hr />
      <div style={{ fontWeight: "bold" }} className="mb-2 mt-2">
        Item Mutasi
      </div>
      <DataTable />
    </>
  );
};

export default TableSection;
