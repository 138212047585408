import {
  IoCashOutline,
  IoSpeedometerOutline,
  IoServerOutline,
  IoDocumentTextOutline,
  IoCheckboxOutline,
  IoPersonCircleOutline,
} from "react-icons/io5";

/* 
  MENU REQUIREMENTS
    > TEXT  = REQUIRED
    > LINK  = REQUIRED
    > EXACT = OPTIONAL (TRUE/FALSE)
    > TYPE  = REQUIRED (MENU/DROPDOWN)
    > HAK   = REQUIRED (HARUS BERBENTUK ARRAY)
    > ICON  = OPTIONAL (REACT-ICONS)
*/

export default [
  {
    text: "Dashboard",
    link: "/",
    type: "menu",
    exact: true,
    icon: <IoSpeedometerOutline />,
    hak: [
      "SUPA",
      "HRDU",
      "HRDU_APR_PRO",
      "HRDU_APR_RP",
      "HRDU_APR_REGKEG",
      "HRDU_APR_PR",
      "HRDU_APR_REGRAB",
      "HRDU_APR_REGPNW",
      "HRDU_APR_PVSPR",
      "HRDU_APR_REGPRO",
      "HRDU_APR_PENPO",
      "HRDU_APR_REGFPNJ",
      "HRDU_APR_PENFPNJ",
      "HRDU_APR_REGDO",
      "HRDU_APR_REGPO",
      "HRDU_APR_REGRAE",
      "HRDU_APR_REGRAP",
      "HRDU_APR_REGSJ",
      "HRDU_APR_REGSO",
      "HRDU_APR_REGSPK",
      "HRDU_APR_RK",
      "HRDU_APR_REGPPA",
      "HRDU_APR_MAG",
      "HRDU_APR_REGSKG",
      "HRDU_APR_REGRKA",
      "HRDU_APR_REGGCPNKB",
      "HRDU_APR_REGGCPLKB",
      "HRDU_APR_REGGCNON",
      "HRDU_APR_REGGCNON",
      "HRDU_APR_REGGCMTKB",
      "HRDU_APR_REGAPPH",
      "HRDU_APR_REGGL",
      "HRDU_APR_REGAPPVC",
      "HRDU_APR_REGAPPSH",
      "HRDU_APR_REGARPP",
      "HRDU_APR_REGARPIV",
      "HRDU_APR_REGARPNP",
    ],
  },
  {
    text: "Approval",
    type: "dropdown",
    icon: <IoCheckboxOutline />,
    hak: [
      "SUPA",
      "HRDU",
      "HRDU_APR_PRO",
      "HRDU_APR_RP",
      "HRDU_APR_REGKEG",
      "HRDU_APR_PR",
      "HRDU_APR_REGRAB",
      "HRDU_APR_REGPNW",
      "HRDU_APR_PVSPR",
      "HRDU_APR_REGPRO",
      "HRDU_APR_PENPO",
      "HRDU_APR_REGFPNJ",
      "HRDU_APR_PENFPNJ",
      "HRDU_APR_REGDO",
      "HRDU_APR_REGPO",
      "HRDU_APR_REGRAE",
      "HRDU_APR_REGRAP",
      "HRDU_APR_REGSJ",
      "HRDU_APR_REGSO",
      "HRDU_APR_REGSPK",
      "HRDU_APR_RK",
      "HRDU_APR_REGPPA",
      "HRDU_APR_MAG",
      "HRDU_APR_REGSKG",
      "HRDU_APR_REGRKA",
      "HRDU_APR_REGGCPNKB",
      "HRDU_APR_REGGCPLKB",
      "HRDU_APR_REGGCNON",
      "HRDU_APR_REGGCNON",
      "HRDU_APR_REGGCMTKB",
      "HRDU_APR_REGAPPH",
      "HRDU_APR_REGGL",
      "HRDU_APR_REGAPPVC",
      "HRDU_APR_REGAPPSH",
      "HRDU_APR_REGARPP",
      "HRDU_APR_REGARPIV",
      "HRDU_APR_REGARPNP",
    ],
    menu: [
      {
        text: "Program",
        link: "/human-resource/approval/program",
        hak: ["SUPA", "HRDU_APR_REGPRG"],
      },
      {
        text: "Kegiatan",
        link: "/human-resource/approval/kegiatan",
        hak: ["SUPA", "HRDU_APR_REGKEG", "HRDU_APR_RK"],
      },
      {
        text: "Sub Kegiatan",
        link: "/human-resource/approval/sub-kegiatan",
        hak: ["SUPA", "HRDU_APR_REGSKG", "HRDU_APR_RK"],
      },
      {
        text: "RKA",
        link: "/human-resource/approval/rka",
        hak: ["SUPA", "HRDU_APR_REGRKA"],
      },
      {
        text: "PPA",
        link: "/human-resource/approval/ppa",
        hak: ["SUPA", "HRDU_APR_REGPPA"],
      },
      {
        text: "Jurnal Umum",
        link: "/human-resource/approval/jurnal-umum",
        hak: ["SUPA", "HRDU_APR_REGGL"],
      },
      {
        text: "Pengakuan Hutang",
        link: "/human-resource/approval/pengakuan-hutang",
        hak: ["SUPA", "HRDU_APR_REGAPPH"],
      },

      {
        text: "Voucher",
        link: "/human-resource/approval/voucher",
        hak: ["SUPA", "HRDU_APR_REGAPPVC"],
      },
      {
        text: "Penyesuaian Hutang",
        link: "/human-resource/approval/penyesuaian-hutang",
        hak: ["SUPA", "HRDU_APR_REGAPPSH"],
      },
      {
        text: "Pengakuan Piutang",
        link: "/human-resource/approval/pengakuan-piutang",
        hak: ["SUPA", "HRDU_APR_REGARPP"],
      },
      {
        text: "Invoice",
        link: "/human-resource/approval/invoice",
        hak: ["SUPA", "HRDU_APR_REGARPIV"],
      },
      {
        text: "Penyesuaian Piutang",
        link: "/human-resource/approval/penyesuaian-piutang",
        hak: ["SUPA", "HRDU_APR_REGARPNP"],
      },
      {
        text: "Bayar DP Vendor",
        link: "/human-resource/approval/bayar-dp-vendor",
        hak: ["SUPA", "HRDU_APR_REGGCBDPV"],
      },
      {
        text: "Penerimaan DP Customer",
        link: "/human-resource/approval/penerimaan-dp-customer",
        hak: ["SUPA", "HRDU_APR_REGGCPDPK"],
      },
      {
        text: "Pembayaran Voucher",
        link: "/human-resource/approval/pembayaran-voucher",
        hak: ["SUPA", "HRDU_APR_REGGCPLKB"],
      },
      {
        text: "Pembayaran Invoice",
        link: "/human-resource/approval/pembayaran-invoice",
        hak: ["SUPA", "HRDU_APR_REGGCPNKB"],
      },
      {
        text: "Non Voucher Invoice",
        link: "/human-resource/approval/non-voucher-invoice",
        hak: ["SUPA", "HRDU_APR_REGGCNON"],
      },
      {
        text: "Mutasi",
        link: "/human-resource/approval/mutasi",
        hak: ["SUPA", "HRDU_APR_REGGCMTKB"],
      },
      // HRDU_APR_REGARPNP
      {
        text: "RAE",
        link: "/human-resource/approval/rae",
        hak: ["SUPA", "HRDU_APR_REGRAE"],
      },
      {
        text: "RAB",
        link: "/human-resource/approval/rab",
        hak: ["SUPA", "HRDU_APR_REGRAB"],
      },
      {
        text: "RAP",
        link: "/human-resource/approval/rap",
        hak: ["SUPA", "HRDU_APR_REGRAP"],
      },
      {
        text: "Penawaran",
        link: "/human-resource/approval/penawaran",
        hak: ["SUPA", "HRDU_APR_REGPNW"],
      },
      {
        text: "Purchase Order",
        link: "/human-resource/approval/purchase-order",
        hak: ["SUPA", "HRDU_APR_REGPO"],
      },
      {
        text: "Purchase Request (PR)",
        link: "/human-resource/approval/purchase-request",
        hak: ["SUPA", "HRDU_APR_PR"],
      },
      {
        text: "Penerimaan Barang",
        link: "/human-resource/approval/penerimaan-barang",
        hak: ["SUPA", "HRDU_APR_PENPO"],
      },
      {
        text: "Seleksi Vendor",
        link: "/human-resource/approval/seleksi-vendor",
        hak: ["SUPA", "HRDU_APR_PVSPR"],
      },
      {
        text: "Sales Order",
        link: "/human-resource/approval/sales-order",
        hak: ["SUPA", "HRDU_APR_REGSO"],
      },
      {
        text: "Surat Perjanjian Kerja",
        link: "/human-resource/approval/surat-perjanjian-kerja",
        hak: ["SUPA", "HRDU_APR_REGSPK"],
      },
      {
        text: "Faktur Penjualan",
        link: "/human-resource/approval/faktur-penjualan",
        hak: ["SUPA", "HRDU_APR_REGFPNJ"],
      },
      {
        text: "Penerimaan Faktur",
        link: "/human-resource/approval/penerimaan-faktur",
        hak: ["SUPA", "HRDU_APR_PENFPNJ"],
      },
      {
        text: "Delivery Order",
        link: "/human-resource/approval/delivery-order",
        hak: ["SUPA", "HRDU_APR_REGDO"],
      },
      {
        text: "Surat Jalan",
        link: "/human-resource/approval/surat-jalan",
        hak: ["SUPA", "HRDU_APR_REGSJ"],
      },
      {
        text: "Mutasi Antar Gudang",
        link: "/human-resource/approval/mutasi-antar-gudang",
        hak: ["SUPA", "HRDU_APR_MAG"],
      },
    ],
  },
  // {
  //   text: "Profil",
  //   type: "dropdown",
  //   icon: <IoPersonCircleOutline />,
  //   hak: ["SUPA",
  //     "HRDU",
  //     "ASM",
  //     "MKT",
  //     "CRM",
  //     "INV",
  //     "ANG",
  //     "HRDA",
  //     "HRDU_APR_KEG",
  //     "HRDU_APR_PR",
  //     "HRDU_APR_REGRAB",
  //     "HRDU_APR_REGPNW",
  //     "HRDU_APR_PVSPR",
  //     "HRDU_APR_REGPRO",
  //     "HRDU_APR_PENPO",
  //     "HRDU_APR_REGFPNJ",
  //     "HRDU_APR_PENFPNJ",
  //     "HRDU_APR_REGDO",
  //     "HRDU_APR_REGPO",
  //     "HRDU_APR_REGRAE",
  //     "HRDU_APR_REGRAP",
  //     "HRDU_APR_REGSJ",
  //     "HRDU_APR_REGSO",
  //     "HRDU_APR_REGSPK",
  //     "HRDU_APR_RK",
  //     "HRDU_APR_REGPPA",
  //     "HRDU_APR_MAG",
  //   ],
  //   menu: [
  //     {
  //       text: "Akun Saya",
  //       link: "/profil",
  //       hak: ["SUPA",
  //         "HRDU",
  //         "ASM",
  //         "MKT",
  //         "CRM",
  //         "INV",
  //         "ANG",
  //         "HRDA",
  //         "HRDU_APR_KEG",
  //         "HRDU_APR_PR",
  //         "HRDU_APR_REGRAB",
  //         "HRDU_APR_REGPNW",
  //         "HRDU_APR_PVSPR",
  //         "HRDU_APR_REGPRO",
  //         "HRDU_APR_PENPO",
  //         "HRDU_APR_REGFPNJ",
  //         "HRDU_APR_PENFPNJ",
  //         "HRDU_APR_REGDO",
  //         "HRDU_APR_REGPO",
  //         "HRDU_APR_REGRAE",
  //         "HRDU_APR_REGRAP",
  //         "HRDU_APR_REGSJ",
  //         "HRDU_APR_REGSO",
  //         "HRDU_APR_REGSPK",
  //         "HRDU_APR_RK",
  //         "HRDU_APR_REGPPA",
  //         "HRDU_APR_MAG",
  //       ],
  //     },
  //     {
  //       text: "Ubah Akun",
  //       link: "/ubah-profil",
  //       hak: ["SUPA",
  //         "HRDU",
  //         "ASM",
  //         "MKT",
  //         "CRM",
  //         "INV",
  //         "ANG",
  //         "HRDA",
  //         "HRDU_APR_KEG",
  //         "HRDU_APR_PR",
  //         "HRDU_APR_REGRAB",
  //         "HRDU_APR_REGPNW",
  //         "HRDU_APR_PVSPR",
  //         "HRDU_APR_REGPRO",
  //         "HRDU_APR_PENPO",
  //         "HRDU_APR_REGFPNJ",
  //         "HRDU_APR_PENFPNJ",
  //         "HRDU_APR_REGDO",
  //         "HRDU_APR_REGPO",
  //         "HRDU_APR_REGRAE",
  //         "HRDU_APR_REGRAP",
  //         "HRDU_APR_REGSJ",
  //         "HRDU_APR_REGSO",
  //         "HRDU_APR_REGSPK",
  //         "HRDU_APR_RK",
  //         "HRDU_APR_REGPPA",
  //         "HRDU_APR_MAG",
  //       ],
  //     },
  //     {
  //       text: "Ganti Password",
  //       link: "/ganti-password",
  //       hak: ["SUPA",
  //         "HRDU",
  //         "ASM",
  //         "MKT",
  //         "CRM",
  //         "INV",
  //         "ANG",
  //         "HRDA",
  //         "HRDU_APR_KEG",
  //         "HRDU_APR_PR",
  //         "HRDU_APR_REGRAB",
  //         "HRDU_APR_REGPNW",
  //         "HRDU_APR_PVSPR",
  //         "HRDU_APR_REGPRO",
  //         "HRDU_APR_PENPO",
  //         "HRDU_APR_REGFPNJ",
  //         "HRDU_APR_PENFPNJ",
  //         "HRDU_APR_REGDO",
  //         "HRDU_APR_REGPO",
  //         "HRDU_APR_REGRAE",
  //         "HRDU_APR_REGRAP",
  //         "HRDU_APR_REGSJ",
  //         "HRDU_APR_REGSO",
  //         "HRDU_APR_REGSPK",
  //         "HRDU_APR_RK",
  //         "HRDU_APR_REGPPA",
  //         "HRDU_APR_MAG",
  //       ],
  //     },
  //   ],
  // },
];
