import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Axios from "axios";
import * as Yup from "yup";
import {
  Alert,
  DataStatus,
  BackButton,
  TextArea,
  ActionButton,
  Th,
  Tr,
  Td,
  TBody,
  CRUDLayout,
  TdFixed,
  ThFixed,
  THead,
  RadioButton,
  DatePicker,
  Input,
  SelectSearch,
  Table,
  PopUpAlert,
} from "../../../components";
import { Row, Col, Card, Modal } from "react-bootstrap";
import { RupiahConvert, DateConvert } from "../../../utilities";
import { Formik } from "formik";
import { SubKegiatanApi } from "../../../api";
import { InfoSection, FormSection, TableSection } from "./Section";

const DetailSubKegiatan = ({ setNavbarTitle }) => {
  const location = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const { no_sub_kegiatan, tab } = location.state;
  const [dataInfo, setDataInfo] = useState({});
  const [dataTable, setDataTable] = useState([]);
  const [approveStatus, setApproveStatus] = useState("V");
  const [fetchingStatus, setFetchingStatus] = useState({
    loading: true,
    success: false,
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    type: "",
    title: "",
    text: "",
    hide: () => setAlertConfig({ ...alertConfig, show: false }),
  });

  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });

  // Mapping data
  const mappingDataInfo = (data) => {
    return {
      id_kegiatan: data?.kegiatan?.id_kegiatan ?? null,
      tgl_kegiatan: data?.kegiatan?.tgl_kegiatan ?? null,
      no_kegiatan: data?.kegiatan?.no_kegiatan ?? "-",
      nama_kegiatan: data?.kegiatan?.nama_kegiatan ?? "-",
      penanggung_jawab: data?.kegiatan?.nama_karyawan ?? "-",
      periode_awal: data?.kegiatan?.periode_mulai ?? null,
      periode_akhir: data?.kegiatan?.periode_selesai ?? null,
      program: data?.kegiatan?.nama_program ?? "-",
      tgl_sub_kegiatan: data?.tgl_sub_kegiatan ?? null,
      no_sub_kegiatan: data?.no_sub_kegiatan ?? null,
      tgl_mulai: data?.tgl_mulai_sub_kegiatan ?? null,
      tgl_selesai: data?.tgl_selesai_sub_kegiatan ?? null,
      nama_sub_kegiatan: data?.nama_sub_kegiatan ?? null,
      id_penanggung_jawab: data?.id_penanggung_jawab,
      keterangan: data?.keterangan,
      is_aset: data?.is_aset ?? false,
      is_wip: data?.is_wip ?? false,
      baseline: data?.baseline ?? null,
    };
  };
  const mappingDataTable = (data) =>
    data.map((val) => {
      return {
        is_terdaftar: "",
        id_kategori: val.id_kategori_sumber_daya ?? null,
        nama_kategori: val.nama_kategori_sumber_daya ?? "-",
        id_buaso: val.id_buaso ?? null,
        nama_buaso: val.nama_buaso ?? "-",
        id_item: val.id_item_buaso ?? null,
        nama_item: val.item_sumber_daya
          ? val.item_sumber_daya
          : val.item_sumber_daya_tampil
          ? val.item_sumber_daya_tampil
          : "-",
        id_satuan: val.id_satuan_sumber_daya ?? null,
        nama_satuan: val.nama_satuan_sumber_daya ?? "",
        id_pengadaan: val.id_pengadaan_sumber_daya ?? null,
        nama_pengadaan: val.kode_sumber_pengadaan
          ? val.kode_sumber_pengadaan === "petty_cash"
            ? "Petty Cash"
            : "Procurement"
          : "-",
        id_pengadaan: val.kode_sumber_pengadaan ?? null,
        id_anggaran: val.id_tipe_anggaran ?? null,
        pos_anggaran: val.pos_anggaran ?? "-",
        no_anggaran: val.no_coa ?? null,
        qty_item: val.qty_sumber_daya ? parseFloat(val.qty_sumber_daya) : 0,
        harga: val.harga_satuan ? parseFloat(val.harga_satuan) : 0,
      };
    });

  // Kebutuhan Formik
  const formInitialValues = {
    id_kegiatan: dataInfo.id_kegiatan ?? null,
    id_sub_kegiatan: id,
    tgl_sub_kegiatan: dataInfo?.tgl_sub_kegiatan
      ? DateConvert(new Date(dataInfo.tgl_sub_kegiatan)).detail
      : null,
    no_sub_kegiatan: dataInfo?.no_sub_kegiatan ?? null,
    tgl_mulai: dataInfo?.tgl_mulai ? DateConvert(new Date(dataInfo.tgl_mulai)).detail : null,
    tgl_selesai: dataInfo?.tgl_selesai ? DateConvert(new Date(dataInfo.tgl_selesai)).detail : null,
    nama_sub_kegiatan: dataInfo?.nama_sub_kegiatan ?? null,
    id_penanggung_jawab: dataInfo?.id_penanggung_jawab ?? null,
    penanggung_jawab: dataInfo?.penanggung_jawab ?? "-",
    keterangan: dataInfo?.keterangan ?? null,
    is_aset: dataInfo?.is_aset ?? null,
    is_wip: dataInfo?.is_wip ?? null,
    catatan: "",
  };
  const formValidationSchema = Yup.object().shape({
    tgl_sub_kegiatan: Yup.string().required("Pilih tgl. sub kegiatan"),
    no_sub_kegiatan: Yup.string().required("Pilih tgl. untuk menentukan nomor"),
    tgl_mulai: Yup.string().required("Pilih tgl. mulai"),
    tgl_selesai: Yup.string().required("Pilih tgl. selesai"),
    nama_sub_kegiatan: Yup.string()
      .required("Masukan nama sub kegiatan")
      .test({
        name: "check-nama",
        message: "Nama program tidak dapat digunakan karena sudah diregister",
        test: (val) =>
          val === dataInfo.nama_sub_kegiatan
            ? true
            : SubKegiatanApi.checkNama({ nama: val })
                .then(() => true)
                .catch(() => false),
      }),
    id_penanggung_jawab: Yup.string().required("Pilih penanggung jawab"),
  });
  const formSubmitHandler = (values, { setSubmitting }) => {
    const dataSumberDaya = dataTable.map((val) => {
      return {
        id_kategori_sumber_daya: val.id_kategori,
        id_item_buaso: val.id_item ? (val.id_item === "" ? null : val.id_item) : null,
        id_satuan_sumber_daya: val.id_satuan ?? null,
        id_tipe_anggaran: val.id_anggaran,
        item_sumber_daya: val.nama_item,
        qty_sumber_daya: val.qty_item,
        harga_satuan: val.harga,
        kode_sumber_pengadaan: val.id_pengadaan,
        no_coa: val.no_anggaran,
        id_coa: val.id_anggaran,
      };
    });

    const finalValues = {
      id_sub_kegiatan: values.id_sub_kegiatan,
      id_kegiatan: values.id_kegiatan,
      id_penanggung_jawab: values.id_penanggung_jawab,
      tgl_sub_kegiatan: values.tgl_sub_kegiatan,
      no_sub_kegiatan: values.no_sub_kegiatan,
      nama_sub_kegiatan: values.nama_sub_kegiatan,
      tgl_mulai_sub_kegiatan: values.tgl_mulai,
      tgl_selesai_sub_kegiatan: values.tgl_selesai,
      keterangan: values.keterangan,
      is_aset: values.is_aset,
      is_wip: values.is_wip,
      sumber_daya: dataSumberDaya ?? [],
    };

    SubKegiatanApi.save(finalValues)
      .then(() => {
        setAlertConfig({
          ...alertConfig,
          show: true,
          type: "success",
          title: "Ubah Berhasil!",
          text: "Data sub kegiatan berhasil diubah!",
          hide: () => history.push("/anggaran/transaksi/sub-kegiatan"),
        });
      })
      .catch(() => {
        setAlertConfig({
          ...alertConfig,
          show: true,
          type: "danger",
          title: "Ubah Gagal!",
          text: "Data sub kegiatan gagal diubah!",
          hide: () => setAlertConfig({ ...alertConfig, show: false }),
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getInitialData = () => {
    setFetchingStatus({
      loading: true,
      success: false,
    });

    Axios.all([SubKegiatanApi.getSingle({ no_sub_kegiatan: no_sub_kegiatan })])
      .then(
        Axios.spread((kegiatan) => {
          const rawKegiatan = kegiatan?.data?.data?.detail;
          console.log(rawKegiatan);
          const rawTabel = rawKegiatan.detail;
          const mapKegiatan = mappingDataInfo(rawKegiatan ?? {});
          const mapTabel = mappingDataTable(rawTabel ?? []);

          setDataInfo(mapKegiatan);
          setDataTable(mapTabel);
          setFetchingStatus({
            loading: false,
            success: true,
          });
        })
      )
      .catch(() => {
        setFetchingStatus({
          loading: false,
          success: false,
        });
      });
  };

  const checkAlert = () => {
    const locationState = location.state;

    if (locationState) {
      if (locationState.alert) {
        setAlertConfig({
          show: locationState.alert.show,
          text: locationState.alert.text,
          variant: locationState.alert.variant,
        });
      }
    }
  };

  const getConvertedDate = (inputDate) => {
    if (inputDate != null) {
      const date = new Date(inputDate);
      return DateConvert(date).custom;
    }
    return "-";
  };

  const ModalSection = ({ values, isSubmitting, handleSubmit }) => {
    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <p>
            <h6>
              <b>
                {modalConfig.title === "approve"
                  ? approveStatus === "A"
                    ? "Approve"
                    : "Verify"
                  : modalConfig.title}{" "}
                dengan catatan :
              </b>
            </h6>
            <span>{values.catatan}</span>
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "APP"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "REJ"
                ? "Reject"
                : "Revise"
            }
            variant={
              modalConfig.type === "APP"
                ? "success"
                : modalConfig.type === "REV"
                ? "warning"
                : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );
    console.log(modalConfig);
    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ values, handleChange, validateForm, errors, touched, setTouched, dirty }) => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="col-md-4 mb-4">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Sub Kegiatan</b>
        </Card.Header>
        <Card.Body>
          <div className="d-flex align-content-between flex-wrap">
            {/* {dataTable.map((val, index) => (
              <InfoItem
                key={index}
                title1={
                  val.status_approval == "VER" ? `PEMERIKSA ${val.approval_level}` : "PENGESAH"
                }
                value1={val.nama_karyawan ?? "-"}
                title2={"Catatan"}
                value2={val.catatan ?? "-"}
              />
            ))} */}
          </div>
          <hr />
          {/* HIDE INPUT TEXT AREA KETIKA TAB HISTORY */}
          {tab !== "history" && (
            <div>
              <TextArea
                label="Catatan"
                name="catatan"
                placeholder="Masukan catatan"
                value={values.catatan}
                onChange={handleChange}
                error={errors.catatan && true}
                errorText={errors.catatan}
              />
              <div className="d-flex justify-content-end mt-3">
                {approveStatus === "A" ? (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject Sub Kegiatan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: <span className="text-success">Approve Sub Kegiatan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                ) : (
                  <>
                    <ActionButton
                      text="Reject"
                      variant="danger"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REJ",
                            title: <span className="text-danger">Reject Sub Kegiatan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text="Revise"
                      variant="warning"
                      className="m-1 text-white"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "REV",
                            title: <span className="text-warning">Revise Sub Kegiatan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                    <ActionButton
                      text={approveStatus === "A" ? "Approve" : "Verify"}
                      variant="success"
                      className="m-1"
                      onClick={() => {
                        if (dirty) {
                          setModalConfig({
                            show: true,
                            type: "APP",
                            title: <span className="text-success">Approve Sub Kegiatan</span>,
                          });
                        }
                        validateForm();
                      }}
                    />
                  </>
                )}
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    );
  };

  useEffect(() => {
    setNavbarTitle("Approval Sub Kegiatan");
    getInitialData();
    checkAlert();

    return () => {
      // setIsPageLoading(false);
    };
  }, []);

  // Tampilan saat loading atau data gagal dimuat
  if (fetchingStatus.loading || !fetchingStatus.success) {
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Sub Kegiatan</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          {fetchingStatus.loading ? (
            <DataStatus loading text="Memuat data . . ." />
          ) : (
            <DataStatus text="Data gagal dimuat" />
          )}
        </Card.Body>
      </Card>
    );
  }

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Sub Kegiatan</b>
          <BackButton size="sm" onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Formik
            initialValues={formInitialValues}
            validationSchema={Yup.object().shape({
              catatan: Yup.string().required("Masukan Catatan"),
            })}
            onSubmit={(values, { setSubmitting }) => {
              const finalValues = {
                ...values,
                no_transaksi: dataInfo.no_sub_kegiatan,
                status_approval: modalConfig.type,
                tgl_approval: DateConvert(new Date()).default,
                approval_baseline: dataInfo.baseline,
              };

              SubKegiatanApi.approve(finalValues)
                .then((res) => {
                  history.push("/human-resource/approval/sub-kegiatan", {
                    alert: {
                      show: true,
                      variant: "primary",
                      text: `${
                        modalConfig.type == "APP"
                          ? "Approve"
                          : modalConfig.type == "REV"
                          ? "Revise"
                          : "Reject"
                      } data berhasil!`,
                    },
                  });
                })
                .catch((err) => {
                  setAlertConfig({
                    show: true,
                    variant: "danger",
                    text: `Simpan approval gagal! (${err?.response?.data?.message ?? ""})`,
                  });
                  setSubmitting(false);
                })
                .finally(() => setModalConfig({ show: false }));
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <InfoSection dataInfo={dataInfo} />
                <hr />
                <FormSection formik={formik} data={dataInfo} />
                <hr />
                <TableSection dataTable={dataTable} setDataTable={setDataTable} />
                <FormCard
                  values={formik.values}
                  handleChange={formik.handleChange}
                  errors={formik.errors}
                  touched={formik.touched}
                  validateForm={formik.validateForm}
                  dirty={formik.dirty}
                />
                <ModalSection
                  values={formik.values}
                  isSubmitting={formik.isSubmitting}
                  handleChange={formik.handleChange}
                  handleSubmit={formik.handleSubmit}
                />
              </form>
            )}
          </Formik>
        </Card.Body>
      </Card>

      {/* Alert */}
      <PopUpAlert
        show={alertConfig.show}
        onConfirm={alertConfig.hide}
        onHide={alertConfig.hide}
        title={alertConfig.title}
        text={alertConfig.text}
        type={alertConfig.type}
      />
    </>
  );
};

export default DetailSubKegiatan;
