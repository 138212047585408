import React from 'react'

import { RupiahConvert } from '../../../../utilities'

const TableLC = ({ dataListLC, setTotalLC }) => {

    const tableStyling = {
        padding: '0.5px',
        fontSize: '14px',
        verticalAlign: 'middle'
    }

    // FUNGSI HITUNG TOTAL KESELURUHAN LC
    const totalLC = () => {
        let totalLC = dataListLC.reduce(function (accumulator, { subtotal }) {
            return accumulator + subtotal;
        }, 0);

        setTotalLC(totalLC)

        return RupiahConvert(String(totalLC)).detail
    }

    return (
        <>
            <div className="p-2 mt-3">
                <b>List Item Labour Cost On-Site</b>
            </div>
            <table className="table table-bordered bg-white table-sm">
                <thead className="text-center bg-light">
                    <tr key="head1">
                        <th className="align-middle" style={tableStyling}>No.</th>
                        <th className="align-middle" style={{ ...tableStyling, width: '20px' }}>Kode Item</th>
                        <th className="align-middle" style={tableStyling}>Item Labour Cost On-Site</th>
                        <th className="align-middle" style={tableStyling}>Qty</th>
                        <th className="align-middle" style={tableStyling}>Unit</th>
                        <th className="align-middle" style={{ ...tableStyling, width: '151px' }}>Unit Price</th>
                        <th className="align-middle" style={tableStyling}>Konst.</th>
                        <th className="align-middle" style={{ ...tableStyling, width: '124px' }}>Sub Total (Rp)</th>
                    </tr>
                </thead>
                <tbody>
                    {dataListLC && dataListLC.length > 0 && dataListLC.map((val, index) => {
                        // SET SUBTOTAL KE OBJECT
                        val.subtotal = (val.unit_price * val.qty) * val.konstanta
                        return (
                            <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td style={tableStyling} className="px-2">{val.kode_item}</td>
                                <td style={tableStyling} className="px-2">{val.nama_item}</td>
                                <td style={tableStyling} className="text-right">{val.qty}</td>
                                <td style={tableStyling} className="px-2">{val.nama_satuan}</td>
                                <td style={tableStyling} className="text-right">{RupiahConvert(String(parseInt(val.unit_price))).detail}</td>
                                <td style={tableStyling} className="text-right px-2">{val.konstanta}</td>
                                <td style={tableStyling} className="text-right">{RupiahConvert(val.subtotal.toString()).detail}</td>
                            </tr>)
                    })}

                    <tr className='bg-light'>
                        <td colSpan={7} className="text-right p-2" style={tableStyling}>
                            <b>Total: </b>
                        </td>
                        <td className="text-right text-nowrap" style={tableStyling}>
                            <b>{totalLC()}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

export default TableLC
