import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import {
  Alert,
  BackButton,
  ActionButton,
  DataStatus,
  Input,
  DatePicker,
  TextArea,
} from "../../../components";
import { DateConvert, RupiahConvert } from "../../../utilities";
import { SPKApi } from "../../../api";
import AnalisaBarangJadiSPK from "./AnalisaBarangJadiSPK";

const DetailApprovalSPK = ({ setNavbarTitle }) => {
  const history = useHistory();
  const { state } = useLocation();
  const noSPK = state?.no_spk;
  const tab = state?.tab ?? '';
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingFailed, setIsFetchingFailed] = useState(false);
  const [dataSPK, setDataSPK] = useState([]);
  const [processedData, setProcessedData] = useState({});
  const [modalAnalisaConfig, setModalAnalisaConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [modalConfig, setModalConfig] = useState({
    show: false,
    type: "",
    title: "",
  });
  const [alertConfig, setAlertConfig] = useState({
    show: false,
    variant: "primary",
    text: "",
  });
  const [approveStatus, setApproveStatus] = useState("V");

  const getInitialData = () => {
    setIsPageLoading(true);

    Axios.all([SPKApi.getSingle({ no_spk: noSPK })])
      .then(
        Axios.spread((res) => {
          setDataSPK(res.data.data);
          setApproveStatus(res.data.data.behavior);
        })
      )
      .catch(() => {
        setIsFetchingFailed(true);
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Data gagal dimuat!",
        });
      })
      .finally(() => setIsPageLoading(false));
  };

  const whenNoSPK = () => {
    setIsPageLoading(false);
    setIsFetchingFailed(true);
  };

  const formSubmitHandler = (values) => {
    const today = new Date().toISOString().slice(0, 10);

    const checkStatus = () => {
      if (modalConfig.type === "revise") {
        return "REV";
      }

      if (modalConfig.type === "approve") {
        return "APP";
      }

      if (modalConfig.type === "reject") {
        return "REJ";
      }
    };

    const finalValues = {
      no_transaksi: dataSPK.detail.no_transaksi,
      status_approval: checkStatus(),
      catatan: values.catatan,
      tgl_approval: today,
      approval_baseline: dataSPK.detail.baseline,
    };

    SPKApi.save(finalValues)
      .then(() =>
        setAlertConfig({
          show: true,
          variant: "primary",
          text: "Approval berhasil disimpan!",
        })
      )
      .catch(() =>
        setAlertConfig({
          show: true,
          variant: "danger",
          text: "Approval gagal disimpan!",
        })
      )
      .finally(() => {
        setModalConfig({
          ...modalConfig,
          show: false,
        });
        getInitialData();
      });
  };

  useEffect(() => {
    setNavbarTitle("Surat Perjanjian Kerja");
    getInitialData();
    noSPK ? getInitialData() : whenNoSPK();

    return () => {
      setIsPageLoading(false);
    };
  }, []);

  const Th = (props) => (
    <th
      {...props}
      className={`${props.className} align-middle`}
      style={{
        ...props.style,
        fontSize: "14px",
      }}
    >
      {props.children}
    </th>
  );

  const Td = (props) => (
    <td
      {...props}
      className={props.className}
      style={{
        ...props.style,
        fontSize: "14px",
      }}
    >
      {props.children}
    </td>
  );

  const InfoSection = () => {
    const InfoItem = ({ title, value }) => (
      <tr>
        <td>{title}</td>
        <td className="pl-4 pr-2">:</td>
        <td>{value}</td>
      </tr>
    );

    return (
      <table style={{ fontSize: "14px" }}>
        <tbody>
          <InfoItem
            title="Tgl. Penawaran"
            value={
              dataSPK?.detail?.tgl_penawaran
                ? DateConvert(new Date(dataSPK?.detail?.tgl_penawaran)).detail
                : "-"
            }
          />
          <InfoItem
            title="No. Penawaran"
            value={dataSPK?.detail?.no_penawaran ? dataSPK?.detail?.no_penawaran : "-"}
          />
          <InfoItem
            title="Customer"
            value={dataSPK?.detail?.nama_customer ? dataSPK?.detail?.nama_customer : "-"}
          />
          <InfoItem title="ATT" value={dataSPK?.detail?.att ? dataSPK?.detail?.att : "-"} />
          <InfoItem
            title="Tgl. Selesai"
            value={
              dataSPK?.detail?.tgl_selesai
                ? DateConvert(new Date(dataSPK?.detail?.tgl_selesai)).detail
                : "-"
            }
          />
          <InfoItem
            title="Tgl. SPK"
            value={
              dataSPK?.detail?.tgl_spk
                ? DateConvert(new Date(dataSPK?.detail?.tgl_spk)).detail
                : "-"
            }
          />
          <InfoItem
            title="No. SPK"
            value={dataSPK?.detail?.no_spk ? dataSPK?.detail?.no_spk : "-"}
          />
          <InfoItem
            title="Tgl. Selesai SPK"
            value={
              dataSPK?.detail?.tgl_selesai_spk
                ? DateConvert(new Date(dataSPK?.detail?.tgl_selesai_spk)).detail
                : "-"
            }
          />
        </tbody>
      </table>
    );
  };

  const TableSPKSection = () => {
    const dataPPN = dataSPK?.detail?.ppn ? dataSPK.detail.ppn : 0;
    const dataTableSPK = dataSPK?.detail.detail_barang_jadi
      ? dataSPK.detail.detail_barang_jadi
      : [];

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;
      const total = parseInt(dataQty) * parseInt(dataHarga);

      return RupiahConvert(total.toString()).detail;
    };

    const getJumlahPPN = () => {
      const total = getTotal();
      const ppn = dataPPN;
      const totalPPN = (total / 100) * ppn;

      return totalPPN;
    };

    const getTotal = () => {
      const subTotal = dataTableSPK.reduce((prev, current) => {
        const qty = current.qty_spk ? current.qty_spk : 0;
        const price = current.harga_rate ? current.harga_rate : 0;
        const total = parseInt(price) * parseFloat(qty);

        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal;
    };

    const getTotalAfterPPN = () => {
      const total = getTotal();
      const ppn = dataPPN;
      const totalPPN = (total / 100) * ppn;
      const totalAfterPPN = parseInt(total) + parseFloat(totalPPN);

      return totalAfterPPN;
    };

    return (
      <>
        <div className="p-1 py-2">
          <b>List Item Barang Jadi SPK</b>
        </div>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th rowSpan={2} className="text-center p-0" style={{ width: 30 }}>
                No
              </Th>
              <Th rowSpan={2} className="text-center p-0" style={{ width: 100 }}>
                Kode Item Barang Jadi
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Nama Item Barang Jadi
              </Th>
              <Th colSpan={2} className="text-center p-0">
                Volume
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Uraian
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Harga Rate
              </Th>
              <Th rowSpan={2} className="text-center p-0">
                Sub Total Rate
              </Th>
            </tr>
            <tr>
              <Th className="text-center p-0">Qty</Th>
              <Th className="text-center p-0">Unit</Th>
            </tr>
          </thead>
          <tbody>
            {dataTableSPK.length > 0 ? (
              dataTableSPK.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="px-1">{val.kode_item}</Td>
                  <Td className="px-1">
                    {<a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setProcessedData({
                          ...val,
                          index: index,
                        });
                        setModalAnalisaConfig({
                          show: true,
                          type: "analisa",
                          title: <span className="text-primary">Analisa Barang Jadi SPK</span>,
                        });
                      }}
                    >
                      {val.nama_item}
                    </a>}
                  </Td>
                  <Td className="px-1 text-right">{val.qty_spk}</Td>
                  <Td className="px-1">{val.nama_satuan}</Td>
                  <Td className="px-1">{val.uraian}</Td>
                  <Td className="px-1 text-right">
                    {val.harga_rate
                      ? RupiahConvert(parseInt(val.harga_rate).toString()).detail
                      : ""}
                  </Td>
                  <Td className="px-1 text-right">{getSubTotal(val.qty_spk, val.harga_rate)}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <Th colSpan={7} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">{RupiahConvert(getTotal().toString()).detail}</Th>
            </tr>
            <tr>
              <Th colSpan={7} className="text-right">
                {`PPN (${dataPPN} %)`}
              </Th>
              <Th className="text-right">{RupiahConvert(parseInt(getJumlahPPN()).toString()).detail}</Th>
            </tr>
            <tr>
              <Th colSpan={7} className="bg-light text-right">
                Total Setelah PPN
              </Th>
              <Th className="bg-light text-right">
                {RupiahConvert(parseInt(getTotalAfterPPN()).toString()).detail}
              </Th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  const TableLabourCostSection = () => {
    const dataTable = dataSPK?.detail?.detail_upah ? dataSPK.detail.detail_upah : [];

    const getTotal = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty ? current.qty : 0;
        const price = current.unit_price ? current.unit_price : 0;
        const total = parseInt(price) * parseFloat(qty);

        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal;
    };

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;
      const total = parseInt(dataQty) * parseInt(dataHarga);

      return RupiahConvert(total.toString()).detail;
    };

    return (
      <>
        <div className="p-1 py-2">
          <b>List Item Labour Cost On-Site</b>
        </div>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th>No.</Th>
              <Th>Kode Item</Th>
              <Th>Item Labour Cost On-Site</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty</Th>
              <Th>Harga Perkiraan Sendiri</Th>
              <Th>Sub Total Harga</Th>
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td className="text-right">{val.qty}</Td>
                  <Td className="text-right">
                    {val.unit_price
                      ? RupiahConvert(parseInt(val.unit_price).toString()).detail
                      : "-"}
                  </Td>
                  <Td className="text-right">{getSubTotal(val.qty, parseInt(val.unit_price))}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <Th colSpan={6} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">{RupiahConvert(getTotal().toString()).detail}</Th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  const TableMachineProcess = () => {
    const dataTable = dataSPK?.detail?.detail_alat_mesin ? dataSPK.detail.detail_alat_mesin : [];

    const getTotal = () => {
      const subTotal = dataTable.reduce((prev, current) => {
        const qty = current.qty ? current.qty : 0;
        const price = current.unit_price ? current.unit_price : 0;
        const total = parseInt(price) * parseFloat(qty);

        return parseInt(parseInt(prev) + parseInt(total));
      }, 0);

      return subTotal;
    };

    const getSubTotal = (qty, harga) => {
      const dataQty = qty ? qty : 0;
      const dataHarga = harga ? harga : 0;
      const total = parseInt(dataQty) * parseInt(dataHarga);

      return RupiahConvert(total.toString()).detail;
    };

    return (
      <>
        <div className="p-1 py-2">
          <b>List Item Machine Process On-Site</b>
        </div>
        <Table bordered hovered size="sm">
          <thead className="bg-light text-center">
            <tr>
              <Th>No.</Th>
              <Th>Kode Item</Th>
              <Th>Item Machine Process On-Site</Th>
              <Th>Satuan Beli</Th>
              <Th>Qty</Th>
              <Th>Harga Perkiraan Sendiri</Th>
              <Th>Sub Total Harga</Th>
            </tr>
          </thead>
          <tbody>
            {dataTable.length > 0 ? (
              dataTable.map((val, index) => (
                <tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{val.kode_item}</Td>
                  <Td>{val.nama_item}</Td>
                  <Td>{val.nama_satuan}</Td>
                  <Td className="text-right">{val.qty}</Td>
                  <Td className="text-right">
                    {val.unit_price
                      ? RupiahConvert(parseInt(val.unit_price).toString()).detail
                      : "-"}
                  </Td>
                  <Td className="text-right">{getSubTotal(val.qty, parseInt(val.unit_price))}</Td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className="bg-light text-center py-5">
                  <b>Tidak ada data</b>
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <Th colSpan={6} className="bg-light text-right">
                Total
              </Th>
              <Th className="bg-light text-right">{RupiahConvert(getTotal().toString()).detail}</Th>
            </tr>
          </tfoot>
        </Table>
      </>
    );
  };

  const ModalSection = ({ formik }) => {
    const { values, isSubmitting, handleSubmit } = formik;

    const ModalContent = () => (
      <>
        <Modal.Body className="text-center">
          <h6>
            <b>
              {modalConfig.title === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.title}{" "}
              dengan catatan :
            </b>
          </h6>
          <span>{values.catatan}</span>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <ActionButton
            variant="outline-secondary"
            text="Batal"
            className="px-4"
            onClick={() =>
              setModalConfig({
                ...modalConfig,
                show: false,
              })
            }
          />
          <ActionButton
            text={
              modalConfig.type === "approve"
                ? approveStatus === "A"
                  ? "Approve"
                  : "Verify"
                : modalConfig.type === "reject"
                  ? "Reject"
                  : "Revise"
            }
            variant={
              modalConfig.type === "approve"
                ? "success"
                : modalConfig.type === "revise"
                  ? "warning"
                  : "danger"
            }
            className="m-1 px-3 text-white"
            onClick={handleSubmit}
            loading={isSubmitting}
          />
        </Modal.Footer>
      </>
    );

    return (
      <Modal
        show={modalConfig.show}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2 d-flex align-items-center">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <ModalContent />
      </Modal>
    );
  };

  const FormCard = ({ formik }) => {
    const { values, errors, touched, dirty, validateForm, handleChange } = formik;
    const dataPengaju = dataSPK?.approval ? dataSPK.approval : [];

    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Approval Surat Perjanjian Kerja</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataPengaju.map((val, index) => (
              <Col sm>
                <InfoItem
                  title1={
                    val.status_approval == "VER" ? `Pemeriksa ${val.approval_level}` : "Pengesah"
                  }
                  value1={val.nama_karyawan ?? "-"}
                  title2="Catatan"
                  value2={val.catatan ?? "-"}
                />
              </Col>
            ))}
          </Row>
          {dataPengaju.length > 1 && <hr />}
          <div>
            <TextArea
              label="Catatan"
              name="catatan"
              placeholder="Masukan catatan"
              value={values.catatan}
              onChange={handleChange}
              error={errors.catatan && true}
              errorText={errors.catatan}
            />
            <div className="d-flex justify-content-end mt-3">
              {approveStatus === "A" ? (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject SPK</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: <span className="text-success">Approve SPK</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              ) : (
                <>
                  <ActionButton
                    text="Reject"
                    variant="danger"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "reject",
                          title: <span className="text-danger">Reject SPK</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text="Revise"
                    variant="warning"
                    className="m-1 text-white"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "revise",
                          title: <span className="text-warning">Revise SPK</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                  <ActionButton
                    text={approveStatus === "A" ? "Approve" : "Verify"}
                    variant="success"
                    className="m-1"
                    onClick={() => {
                      if (dirty) {
                        setModalConfig({
                          show: true,
                          type: "approve",
                          title: <span className="text-success">Verify SPK</span>,
                        });
                      }
                      validateForm();
                    }}
                  />
                </>
              )}
            </div>
          </div>
        </Card.Body>
      </Card>
    );
  };

  const ModalAnalisaSection = ({
    processedData,
    dataBarangJadi,
    setDataBarangJadi,
    alertConfig,
    setAlertConfig,
    modalConfig,
    setModalConfig,
  }) => {
    const AnalisaBarangJadiModal = ({
      processedData,
      dataBarangJadi,
      setDataBarangJadi,
      alertConfig,
      setAlertConfig,
      modalConfig,
      setModalConfig,
    }) => {
      return (
        <Modal.Body>
          <AnalisaBarangJadiSPK
            processedData={processedData}
            dataBarangJadi={dataBarangJadi}
            setDataBarangJadi={setDataBarangJadi}
            alertConfig={alertConfig}
            modalConfig={modalConfig}
            setAlertConfig={setAlertConfig}
            setModalConfig={setModalConfig}
          />
        </Modal.Body>
      );
    };

    return (
      <Modal
        scrollable={true}
        show={modalConfig.show}
        size={modalConfig.type === "analisa" ? "xl" : "md"}
        onHide={() =>
          setModalConfig({
            ...modalConfig,
            show: false,
          })
        }
      >
        <Modal.Header closeButton className="py-2">
          <Modal.Title>
            <small>{modalConfig.title}</small>
          </Modal.Title>
        </Modal.Header>
        <AnalisaBarangJadiModal
          processedData={processedData}
          dataBarangJadi={dataBarangJadi}
          setDataBarangJadi={setDataBarangJadi}
          alertConfig={alertConfig}
          setAlertConfig={setAlertConfig}
          modalConfig={modalConfig}
          setModalConfig={setModalConfig}
        />
      </Modal>
    );
  };

  const CatatanApproval = () => {
    const InfoItem = ({ title1, value1, title2, value2 }) => (
      <div className="mb-2">
        <small>{title1}</small>
        <br />
        <b>{value1}</b>
        <div className="mt-1" />
        <small>{title2}</small>
        <br />
        <b>{value2}</b>
      </div>
    );

    return (
      <Card className="mt-4 mb-5">
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Catatan Approval SPK</b>
        </Card.Header>
        <Card.Body>
          <Row>
            {dataSPK?.detail?.stakeholder?.map(
              (val, index) =>
                index !== 0 &&
                val.status_approval !== "PEN" && (
                  <Col sm>
                    <InfoItem
                      title1={
                        val.status_approval === "VER" || val.status_approval === "REV"
                          ? `Pemeriksa ${val.approval_level !== "0" ? val.approval_level : ""}`
                          : val.status_approval === "APP"
                            ? "Pengesah"
                            : "Di Tolak Oleh"
                      }
                      value1={val.nama_karyawan ?? "-"}
                      title2="Catatan"
                      value2={val.catatan ?? "-"}
                    />
                  </Col>
                )
            )}
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <b>Detail Data Surat Perjanjian Kerja</b>
          <BackButton onClick={() => history.goBack()} />
        </Card.Header>
        <Card.Body>
          <Alert
            showCloseButton
            show={alertConfig.show}
            variant={alertConfig.variant}
            text={alertConfig.text}
            onClose={() =>
              setAlertConfig({
                ...alertConfig,
                show: false,
              })
            }
          />
          {isPageLoading ? (
            <DataStatus loading={true} text="Memuat data . . ." />
          ) : isFetchingFailed ? (
            <DataStatus text="Data gagal dimuat!" />
          ) : (
            <>
              <InfoSection />
              <hr />
              <TableSPKSection />
              {/* <TableLabourCostSection />
              <TableMachineProcess /> */}
            </>
          )}
        </Card.Body>
      </Card>
      {!isFetchingFailed && !isPageLoading && tab !== 'history' && (
        <Formik
          initialValues={{
            catatan: "",
          }}
          validationSchema={Yup.object().shape({
            catatan: Yup.string().required("Masukan catatan"),
          })}
          onSubmit={formSubmitHandler}
        >
          {(formik) => (
            <>
              <FormCard formik={formik} />
              <ModalSection formik={formik} />
            </>
          )}
        </Formik>
      )}
      {!isFetchingFailed && !isPageLoading && tab === 'history' && <CatatanApproval />}

      <ModalAnalisaSection
        processedData={processedData}
        dataBarangJadi={dataSPK}
        setDataBarangJadi={setDataSPK}
        alertConfig={alertConfig}
        setAlertConfig={setAlertConfig}
        modalConfig={modalAnalisaConfig}
        setModalConfig={setModalAnalisaConfig}
      />
    </>
  );
};

export default DetailApprovalSPK;
