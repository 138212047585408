// React
import { useState } from 'react'

// Component
import { Row, Col } from 'react-bootstrap'
import { DatePicker, Input, TextArea } from 'components'
import { DateConvert } from 'utilities'

const FormSection = ({formik, dataInfo}) => {
  const {values, errors, touched, handleChange} = formik

  return (
    <>
      <Row>
        {/* Tanggal RKA */}
        <Col md>
          <DatePicker 
            label="Tgl. RKA"
            selected={new Date(dataInfo.tgl_rka)}
            error={Boolean(errors.tgl_rka && touched.tgl_rka)}
            errorText={Boolean(errors.tgl_rka && touched.tgl_rka) && errors.tgl_rka}
            disabled={true}
          />
        </Col>

        {/* No RKA */}
        <Col md>
          <Input 
            readOnly
            label="No. RKA"
            placeholder="No. RKA"
            value={dataInfo.no_rka ?? '-'}
          />
        </Col>

        <Col md>
          <TextArea 
            label="Keterangan RKA"
            placeholder="Keterangan RKA"
            name="catatan_rka"
            onChange={handleChange}
            value={dataInfo.catatan ?? '-'}
            rows={4}
            readOnly
          />
        </Col>
      </Row>
    </>
  )
}

export default FormSection
